
::selection{
  background:#ff0;
}

body {
  color: var(--fg-color);

  // &:target::before {
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   background-image: linear-gradient(to bottom, transparent, transparent 19px, black 19px, black 20px);
  //   background-repeat: repeat-y;
  //   background-size: 100% 20px;
  //   display: block;
  //   content: '';
  //   opacity: 0.1;
  // }
}

.page {
  grid-template-areas:
    "header"
    "main"
    "aside"
  ;
}

header { grid-area: header }
aside { grid-area: aside }
main { grid-area: main }

a {
  color: currentColor;
}

p {
  margin: 10px 0;
}

blockquote {
  font-style: italic;
  display: block;
  border-left: 3px solid var(--main-color);
  padding-left: 8px;
  margin: 2rem 0;
  color: #666;
  color: ~"color(currentColor alpha(75%))";
}

.me {
  &__name {
    transition: all ease 200ms;    
  }
}

.title {
  transition: all ease 200ms;
}

.contacts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.contact {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 30px;
  margin-right: 3rem;
  transition: transform ease 200ms;

  &__icon {
    margin-right: 10px;
    font-size: 20px;
    line-height: 0;
    flex: 0 0 20px;
  }
}

.techs {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
}

.tech {
  align-items: center;
  color: var(--tech-color, #eee);
  display: flex;
  flex: 1 1 130px;
  flex-direction: column;
  margin-bottom: 10px;

  &__symbol {
    align-items: center;
    border: 0.1em solid currentColor;
    border-radius: 50%;
    display: flex;
    font-family: 'Fira Mono', monospace;
    font-size: 25px;
    height: 70px;
    justify-content: center;
    transition: all ease 200ms;
    width: 70px;
    box-sizing: border-box;
  }

  &__name {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-top: 10px;
  }

  &--css { --tech-color: #0075B6 }
  &--html { --tech-color: #d40000 }  // #E6522B
  &--js { --tech-color: #a26300 }  // #E8A232
  &--perf { --tech-color: #007d21 }  // #27ae60
}

.education {
  list-style: circle;
  padding-left: 16px;
}

.hobbies {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

.hobby {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 1rem;

  &__icon {
    font-size: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  &__name {
    font-size: 8px;
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

.name {
  position: relative;
  width: 100%;
  text-align: center;

  &__alternative {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
}

#👇 {
  text-align: center;
}

.download {
  background: var(--main-color);
  border: 3px solid var(--main-color);
  color: #fafafa;
  display: inline-block;
  letter-spacing: 0.2em;
  margin: 4rem 0;
  padding: calc(1rem - 3px) 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease 200ms;

  &:hover {
    background-color: #fff;
    color: var(--main-color);
  }
}
